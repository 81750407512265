import { CFormInput, CFormLabel } from "@coreui/react-pro";
import { DatePicker } from "antd";
import React, { useState } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { FormFieldError } from "src/helpers";
import en from "antd/es/date-picker/locale/en_US";
import dayjs from "dayjs";
import ChangeDateformat from "src/helpers/changeDateformat";
import Multiselect from "multiselect-react-dropdown";

export const ControlledInput = ({
  name,
  label,
  errors = {},
  defaultValue = "",
  ...rest
}) => {
  // console.log("...rest", {...rest});
  const { control } = useFormContext();

  if (!control) {
    console.error("Control prop is undefined or null");
  }

  return (
    <div className="mb-3">
      {label && <CFormLabel htmlFor={name}>{label}</CFormLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <CFormInput {...field} {...rest} />
            {error && <p>{error.message}</p>}
          </>
        )}
      />
    </div>
  );
};

export const ControlledDatePicker = ({
  name,
  label,
  defaultValue = "",
  showTime,
  onChange = null,
  ...rest
  
}) => {
  const { control } = useFormContext();
  if (!control) {
    console.error(`Control prop is undefined or null ${ControlledDatePicker}`);
  }
 
  return (
    <div className="mb-3">
      {label && <CFormLabel className="form-label col-form-label" htmlFor={name}>{label}</CFormLabel>}
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => { 
          // console.log("field", field);
          
          return (
            <>
              <DatePicker
                {...rest}
                {...field}
                format={showTime ? "MM/DD/YYYY HH:mm:ss" : "MM/DD/YYYY"}
                local={{
                  ...en,
                  lang: {
                    ...en.lang,
                    fieldDateFormat: "MM/DD/YYYY",
                    fieldDateTimeFormat: showTime
                      ? "MM/DD/YYYY HH:mm:ss"
                      : "MM/DD/YYYY",
                    yearFormat: "YYYY",
                    cellYearFormat: "YYYY",
                  },
                }}
                // value={field.value ? dayjs(field.value) : dayjs(defaultValue)}
                value={field.value ? dayjs(field.value) : defaultValue ? dayjs(defaultValue) : null}
                // defaultValue={defaultValue && dayjs(defaultValue)}
                onChange={(date, dateString) => { 
                  // console.log("date", date, typeof onChange);

                  ///////////// handle onChange method as a onChange props 
                  ///////////// That will come from the parent
                  if(onChange && typeof onChange == 'function'){
                    onChange(date
                      ? ChangeDateformat(
                          date,
                          showTime ? "withTime" : "date"
                        ).toString()
                      : null)
                  }
                
                      
                  field.onChange(
                    date
                      ? ChangeDateformat(
                          date,
                          showTime ? "withTime" : "date"
                        ).toString()
                      : null
                  );
                }}
                order={false}
                showTime={
                  showTime
                    ? { defaultValue: dayjs("00:00:00", "HH:mm:ss") }
                    : false
                }
                popupStyle={{ zIndex: 10000 }}
                // allowClear={false}
              />
              {/* {console.log("error", error)} */}
              {error ? <FormFieldError error={error} /> : ""}
            </>
          );
        }}
      />
    </div>
  );
};

export const ControlledMultiselect = ({
  name,
  options,
  label,
  displayValue = "name",
  showCheckbox = true,
  avoidHighlightFirstOption = true,
  selectedValues = {},
  defaultValue = "",
  showTime,
  ...rest
}) => {
  const { control, setValue, getValues } = useFormContext();
  if (!control) {
    console.error(`Control prop is undefined or null ${ControlledDatePicker}`);
  }

  const onColorAndStateSelectHandler = (values) => {
    let changedStateValues = [];
    values.map((val) => changedStateValues.push(val.name));
    setValue(name, changedStateValues);
  };
  console.log("getValues", getValues(name));

  return (
    <div className="mb-3">
      {label && <CFormLabel htmlFor={name}>{label}</CFormLabel>}
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <Multiselect
                {...rest}
                {...field}
                options={options}
                selectedValues={selectedValues}
                onSelect={onColorAndStateSelectHandler}
                onRemove={onColorAndStateSelectHandler}
                displayValue={displayValue}
                showCheckbox={showCheckbox}
                avoidHighlightFirstOption={avoidHighlightFirstOption} 
              />
              {error ? <FormFieldError error={error} /> : ""}
            </>
          );
        }}
      />
    </div>
  );
};
export const ControlledFileUpload = ({
  name,
  options,
  label,
  displayValue = "name",
  showCheckbox = true,
  avoidHighlightFirstOption = true,
  selectedValues = {},
  defaultValue = "",
  showTime,
  ...rest
}) => {
  const { control, setValue, getValues } = useFormContext();
  if (!control) {
    console.error(`Control prop is undefined or null ${ControlledFileUpload}`);
  }

  const onColorAndStateSelectHandler = (values) => {
    let changedStateValues = [];
    values.map((val) => changedStateValues.push(val.name));
    setValue(name, changedStateValues);
  };
  console.log("getValues", getValues(name));

  return (
    <div className="mb-3">
      {label && <CFormLabel htmlFor={name}>{label}</CFormLabel>}
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <Multiselect
                {...rest}
                {...field}
                options={options}
                selectedValues={selectedValues}
                onSelect={onColorAndStateSelectHandler}
                onRemove={onColorAndStateSelectHandler}
                displayValue={displayValue}
                showCheckbox={showCheckbox}
                avoidHighlightFirstOption={avoidHighlightFirstOption} 
              />
              {error ? <FormFieldError error={error} /> : ""}
            </>
          );
        }}
      />
    </div>
  );
};
