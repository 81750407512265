import { method } from 'lodash'
import React, { forwardRef } from 'react'
import { FormProvider } from 'react-hook-form'

export const ControlledFormProvider = React.forwardRef(function ControlledFormProvider(props, ref) {
  console.log("props", props, "ref", ref);
  
  const {methods, onSubmit, children, ...other} = props;
  return (
    <FormProvider {...methods} {...other} >
        <form onSubmit={onSubmit} {...other}  ref={ref}>
            {children}
        </form> 
    </FormProvider>
  )
})
