import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import ChangeDateformat from "src/helpers/changeDateformat";

const today = new Date();
const initialState = ChangeDateformat(today);
// const initialState = {
//     value : 1
// };

const dailyReportDateSlice = createSlice({
    name : 'dailyReportDate',
    initialState,
    reducers: { 
        increaseDecreaseReportDate(state, action){
            // console.log("updateDailyReportDate", state, action); 
            let date = new Date(state); 
            if(action.payload =="INCREMENT") {
                const value = ChangeDateformat(moment(date).add(1, "days")._d);
                return state = value
            }else if(action.payload == "DECREMENT") {
                const value = ChangeDateformat(moment(date).subtract(1, "days")._d);
                return state = value
            }else {
                return state
            }
        },    
        updateDailyReportDate(state, action){ 
            // console.log("updateDailyReportDate", state, action, initialState); 
            return state = action.payload == '' || action.payload == null || action.payload == undefined ? initialState :  action.payload
        },    
        resetDailyReportDate(state, action){
            // console.log('state, action',  action);
            // state = state.filter((item)=> item.id !== action.payload)
        }
    }
})
 

export const{testReducer, increaseDecreaseReportDate, updateDailyReportDate, resetDailyReportDate} = dailyReportDateSlice.actions
export default dailyReportDateSlice.reducer